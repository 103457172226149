.helicopter-icon-green-container {
  width: auto;
  height: auto;
  display: flex;
  z-index: 99;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-green-container1 {
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-green-container2 {
  width: 129px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #009A17;
}
.helicopter-icon-green-image {
  width: 100%;
  height: 60px;
  object-fit: contain;
}
.helicopter-icon-green-icon {
  fill: #009A17;
  left: 43px;
  width: 45px;
  bottom: -34px;
  height: 56px;
  z-index: 98;
  position: absolute;
}
.helicopter-icon-green-root-class-name {
  top: 152px;
  left: 436px;
  position: absolute;
}
