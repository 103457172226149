.helicopter-icon-red-container {
  width: auto;
  height: auto;
  display: flex;
  z-index: 99;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-red-container1 {
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-red-container2 {
  width: 129px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #9B2423;
}
.helicopter-icon-red-image {
  width: 100px;
  object-fit: cover;
}
.helicopter-icon-red-icon {
  fill: #9B2423;
  left: 42px;
  width: 45px;
  bottom: -33px;
  height: 56px;
  z-index: 98;
  position: absolute;
}
.helicopter-icon-red-root-class-name {
  top: 153px;
  left: 733px;
  position: absolute;
}
