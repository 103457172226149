.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #565050;
}



.home-navbar {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 83px;
  display: none;
  z-index: 100;
  position: absolute;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #9b2423;
}
.home-navabar-icon-box {
  gap: var(--dl-space-space-unit);
  width: 635px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-navlink {
  display: contents;
}
.home-logo {
  width: 70%;
  height: 50%;
  text-decoration: none;
}
.home-logo1 {
  width: 35%;
  height: 60%;
}
.home-links-box {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-map {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.home-text:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-notifications {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text01 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.home-text01:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-help-center {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text02 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.home-text02:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-mobile-menu {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-profile-icon-dropdown {
  top: 48px;
  left: -63px;
  width: 178px;
  height: 165px;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.home-map1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.home-text03 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.home-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
}
.home-notifications1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.home-text06 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.home-help-center {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.home-text07 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.home-profile-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-image {
  width: 37px;
  height: 36px;
  object-fit: cover;
}
.home-profile-icon-dropdown1 {
  top: 47px;
  left: -62px;
  width: 178px;
  height: 231px;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.home-profile {
  cursor: pointer;
  height: 64px;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.home-text08 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.home-manage-helicopter {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.home-navlink2 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.home-user-management {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.home-navlink3 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.home-help-center-manage {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle11:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.home-text09 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.home-logout {
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.home-dropdown-toggle12 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.home-text10 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.home-map2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: url('https://images.unsplash.com/photo-1544830826-4ccc3bf5ceb1?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDY0fHxtYXB8ZW58MHx8fHwxNjczNTk3MTA2&ixlib=rb-4.0.3&w=1500'); */
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #9b2423;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.filter-dropdown {
  position: absolute;
  top: 100px;
  left: 20px;
  z-index: 10;
}

.filter-select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid rgba(204, 204, 204, 0.3);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-select:hover {
  border-color: rgba(136, 136, 136, 0.8);
  background-color: rgba(255, 255, 255, 0.9);
}

.filter-select:focus {
  outline: none;
  border-color: rgba(136, 136, 136, 1);
  background-color: rgba(255, 255, 255, 1);
}

@media(max-width: 991px) {
  .home-logo1 {
    display: none;
  }
  .home-map {
    display: none;
  }
  .home-notifications {
    display: none;
  }
  .home-help-center {
    display: none;
  }
  .home-mobile-menu {
    display: inline-block;
  }
  .home-icon {
    fill: #ffffff;
    width: 28px;
    height: 28px;
  }
  .home-profile-icon-dropdown {
    height: 100px;
  }
}
@media(max-width: 767px) {
  .helicopter-marker {
    background: #282829;
    padding: 2px !important;
    border-radius: 10px;
    top: -53px!important;
    width: 70px !important;
    text-align: center;
    justify-content: center;
    display: grid;
  }

  .home-container {
    justify-content: flex-start;
  }
  .home-navbar {
    top: 0px;
    left: 0px;
    position: absolute;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-logo1 {
    display: none;
  }
  .home-map {
    display: none;
  }
  .home-notifications {
    display: none;
  }
  .home-help-center {
    display: none;
  }
  .home-mobile-menu {
    display: inline-block;
  }
  .home-dropdown-toggle03 {
    align-items: center;
    justify-content: center;
  }
  .home-icon {
    fill: #ffffff;
    width: 32px;
    height: 32px;
  }
  .home-profile-icon-dropdown {
    top: 48px;
    left: -125px;
    height: 101px;
  }
  .home-text03 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .home-text06 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .home-text07 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
}
@media(max-width: 479px) {
  .home-navbar {
    padding: var(--dl-space-space-unit);
  }
}
@media(max-width: 464px) {
  .home-navbar {
    padding-left: 29px;
    padding-right: 16px;
  }
  .home-logo {
    width: 170px;
    height: 32px;
  }
  .home-links-box {
    gap: 2px;
    width: 172px;
  }
  .home-profile-icon-dropdown1 {
    top: 51px;
    left: -100px;
    height: 165px;
  }
}

.map-container {
  height: 100vh;
  width: 100%;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.marker {
  width: 120px;
  height: 60px;
  background-repeat: no-repeat;
  /* background-color: #282829; */
  background-position-x: center;
  background-position-y: center;
  border-radius: 5%;
  background-size: cover;
}


.helicopter-marker {
  background: #282829;
  padding: 9px;
  border-radius: 10px;
  top: -53px!important;
  width: 115px;
  text-align: center;
  justify-content: center;
  display: grid;
}

.helicopter-marker::before {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  left: 50%;
  bottom: 0;
  border: 15px solid transparent;
  border-bottom: 0;
  border-top: 15px solid #282829;
  transform: translate(-50%, 100%);
}

.status1 {
  background: #009A17;
}

.helicopter-marker.status1::before {
  border-top: 15px solid #009A17;
}



.helicopter-marker.status2::before {
  border-top: 15px solid #009A17;
}

.status2 {
  background: #009A17;
}

.helicopter-marker.status3::before {
  border-top: 15px solid  #F6BE00;
}
.status3 {
  background: #F6BE00;
}
.status4 {
  background: #F6BE00;
}

.helicopter-marker.status4::before {
  border-top: 15px solid #F6BE00;
}

.status5 {
  background: #F6BE00;
}

.helicopter-marker.status5::before {
  border-top: 15px solid #F6BE00;
}

.status6 {
  background: #9B2423;
}

.helicopter-marker.status6::before {
  border-top: 15px solid #9B2423;
}

.status7 {
  background: #282829;
}

.helicopter-marker.status7::before {
  border-top: 15px solid #282829;
}

.status8 {
  background: #0000FF;
}

.helicopter-marker.status8::before {
  border-top: 15px solid #0000FF;
}

p.helicopter-icon-red-hover-text {
  display: none;
  color: rgb(255, 255, 255);
  font-size: 21px; /* Reduced from 12px */
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
  text-align: center;
  line-height: 1.5; /* Added line height for better readability */

}

.helicopter-marker:hover{
  top: -55px!important;
  z-index: 1;
}
.helicopter-status-text {
  font-size: 12px; /* Adjust this value as needed */
  line-height: 1.2; /* Helps with readability for smaller text */
  word-wrap: break-word; /* Ensures long words don't overflow */
  max-width: 100px; /* Adjust as needed to prevent overflow */
}

.helicopter-marker:hover p.helicopter-icon-red-hover-text{
 display: block;
 /* height: 8px; */
 text-align: center !important;
 padding: 0px;
 margin: 0px;  

}

.helicopter-marker:hover  .helicopter-icon-green-image{
  display: none;
  /* height: 8px; */
}


/* ===================================================== */

.rounded-rect {
  background: white;
  border-radius: 20px;
  box-shadow: 0 0 50px -25px black;
  }
   
  .flex-center {
  position: absolute;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  }
   
  .flex-center.left {
  left: 0px;
  }
 
  
.sidebar-content {
  position: absolute;
  width: 95%;
  height: 95%;
  font-family: Poppins;
  font-size: 32px;
  color: gray;
  top: 5%;
  }
   
  .sidebar-toggle {
  position: absolute;
  width: 1.3em;
  height: 1.3em;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  }
   
  .sidebar-toggle.left {
  right: -1.5em;
  }
   
  .sidebar-toggle.right {
  left: -1.5em;
  }
   
  .sidebar-toggle:hover {
  color: #0aa1cf;
  cursor: pointer;
  }
   
  .sidebar {
  transition: transform 1s;
  z-index: 1;
  width: 450px;
  height: 80%;
  }
   
  /*
  The sidebar styling has them "expanded" by default, we use CSS transforms to push them offscreen
  The toggleSidebar() function removes this class from the element in order to expand it.
  */
  .left.collapsed {
  transform: translateX(-450px);
  }

   

  .sidebar .manage-helicopter-card-container {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 25px;
    flex-direction: column;
    background-color: #ffffff;
}

.sidebar .manage-helicopter-card-text02, .sidebar .manage-helicopter-card-text04,.sidebar .manage-helicopter-card-text06,.sidebar .manage-helicopter-card-text10,.sidebar .manage-helicopter-card-text12{
  font-size: 16px;;
}

.sidebar .select-option-status {
/*  background-color: black;*/
  color: white;
  font-size: 16px;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  margin-top: 30px;

  width: 150px;
  overflow: auto;
}
