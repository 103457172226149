.helicopter-icon-stack-container {
  width: auto;
  height: auto;
  display: flex;
  z-index: 99;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-stack-container1 {
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-stack-container2 {
  width: 129px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #009A17;
}
.helicopter-icon-stack-image {
  width: 100px;
  object-fit: cover;
}
.helicopter-icon-stack-container3 {
  width: 129px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #F6BE00;
}
.helicopter-icon-stack-image1 {
  width: 100px;
  object-fit: cover;
}
.helicopter-icon-stack-container4 {
  width: 129px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #9B2423;
}
.helicopter-icon-stack-image2 {
  width: 100px;
  object-fit: cover;
}
.helicopter-icon-stack-container5 {
  width: 129px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #282829;
}
.helicopter-icon-stack-image3 {
  width: 100px;
  object-fit: cover;
}
.helicopter-icon-stack-icon {
  fill: #282829;
  left: 42px;
  width: 45px;
  bottom: -33px;
  height: 56px;
  z-index: 98;
  position: absolute;
}
.helicopter-icon-stack-root-class-name {
  top: 223px;
  left: 67px;
  position: absolute;
}
