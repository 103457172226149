:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #d9d9d9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}
input:focus {
  outline: none;
}

select:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
button:focus {
  outline: none;
}

.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
  outline: none;
}
.input {
  color: #9b2423;
  width: 343px;
  cursor: auto;
  height: 35px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
}

.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: translateX(0%) !important;
}
.lol\:\:placeholder {
  color: #9b2423;
  opacity: 1;
}
.Heading {
  font-size: 32px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading1 {
  font-size: 60px;
  font-family: Poppins;
  font-weight: 700;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.popup-start{
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  background: #000000a1;
  height: 100vh;
  width: 100%;
  clear: both;
  position: fixed;
  overflow: block;
  display: block;
}

.show__popup{
    display: block;
    
}

@media (max-width: 464px) {
  .input {
    width: 280px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

