.add-helicopter-container-add-helicopter-container {
  width: 100%;
  height: 145px;
  display: flex;
  padding: 48px;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}

.textarea {
    color: #9B2423;
  width: 343px;
  cursor: auto;
  /* height: 55px; */
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
/*  margin-bottom: 60px  ;*/
}

.help-center-management-pop-up-textarea {
  color: #9B2423;
  width: 100%;
  height: 101px;
  padding: var(--dl-space-space-unit);
  font-size: 14px;
  font-family: Poppins;
  border-width: 0px;
}
.add-helicopter-container-add-helicopter-button {
  flex: 0 0 auto;
  width: 278px;
  height: 45px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: #9B2423;
}
.add-helicopter-container-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.add-helicopter-container-container {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 900px;
  bottom: 0px;
  height: 600px;
  margin: auto auto;
  display: flex;
  padding: 21px;
  z-index: 101;
  position: fixed;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: #F3F3F3;
}
.add-helicopter-container-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #D9D9D9;
}
.add-helicopter-container-comapany-details {
  width: 100%;
  height: 126px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.add-helicopter-container-text1 {
  color: rgb(158, 36, 35);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.add-helicopter-container-input-feilds {
  width: 100%;
  height: 252px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.add-helicopter-container-c1 {
  gap: 16px;
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.add-helicopter-container-textinput {
   color: #9b2423;
  width: 343px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
}
.add-helicopter-container-textinput1 {
  color: #9b2423;
  width: 343px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
}
.add-helicopter-container-textinput2 {
  color: #9b2423;
  width: 343px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
}
.add-helicopter-container-textinput3 {
  color: #9b2423;
  width: 343px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
}
.add-helicopter-container-c2 {
  gap: 16px;
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.add-helicopter-container-textinput4 {
  color: #9b2423;
  width: 343px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
}
.add-helicopter-container-textinput5 {
  color: #9b2423;
  width: 343px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
}
.add-helicopter-container-textinput6 {
  color: #9b2423;
  width: 343px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
}

.add-helicopter-container-textinput7 {
  color: #9b2423;
  width: 775px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto; /* Center the input within its container */
}

.add-helicopter-container-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 343px;
  height: 36px;
  display: flex;
  align-items: center;
  border-color: #9B2423;
  border-style: solid;
  border-width: 1px;
  border-radius: 14px;
  flex-direction: row;
  justify-content: flex-start;
}
.add-helicopter-container-container3 {
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.add-helicopter-container-container3:hover {
  background-color: #d2d2d2;
}
.add-helicopter-container-div {
  width: 100%;
  height: 100%;
}
.add-helicopter-container-text2 {
  color: #9b2423;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.add-helicopter-container-container4 {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.add-helicopter-container-submit-button {
  width: 100%;
  height: 135px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.add-helicopter-container-done-button {
  width: 100%;
  height: 428px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.add-helicopter-container-container5 {
  flex: 0 0 auto;
  width: 311px;
  height: 44px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #ffffff;
  border-width: 0.5px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: #9E2423;
}
.add-helicopter-container-container5:hover {
  background-color: rgba(158, 36, 35, 0.73);
}
.add-helicopter-container-container5:active {
  box-shadow: 0px 0px 0px 0px #000000;
}
.add-helicopter-container-text3 {
  color: rgb(255, 255, 255);
  font-size: 17px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.add-helicopter-container-close-button {
  top: 4px;
  right: 4px;
  width: 36px;
  height: 36px;
  margin: auto;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  background-color: #9E2423;
  z-index: 111;
  cursor: pointer;
}
.add-helicopter-container-close-button:hover {
  background-color: rgba(158, 36, 35, 0.77);
}
.add-helicopter-container-icon {
  fill: #ffffff;
  width: 25px;
  height: 25px;
}
@media(max-width: 991px) {
  .add-helicopter-container-container {
    width: 750px;
  }
}
@media(max-width: 767px) {
  .add-helicopter-container-container {
    width: 100%;
  }
  .add-helicopter-container-comapany-details {
    height: 55px;
  }
  .add-helicopter-container-input-feilds {
    gap: var(--dl-space-space-unit);
    height: 435px;
    flex-direction: column;
  }
  .add-helicopter-container-c1 {
    width: 100%;
    height: auto;
  }
  .add-helicopter-container-c2 {
    width: 100%;
    height: auto;
  }
  .add-helicopter-container-submit-button {
    height: 67px;
  }
}
@media(max-width: 464px) {
  .add-helicopter-container-textinput {
    width: 280px;
  }
  .add-helicopter-container-textinput1 {
    width: 280px;
  }
  .add-helicopter-container-textinput2 {
    width: 280px;
  }
  .add-helicopter-container-textinput3 {
    width: 280px;
  }
  .add-helicopter-container-textinput4 {
    width: 280px;
  }
  .add-helicopter-container-textinput5 {
    width: 280px;
  }
  .add-helicopter-container-textinput6 {
    width: 280px;
  }
  .add-helicopter-container-container2 {
    width: 280px;
  }
  .add-helicopter-container-container5 {
    width: 270px;
  }
}
.rmsc {
     --rmsc-main:transparent !important ;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: rgba(158, 36, 35, 0.77);
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 10px;
    --rmsc-radius: 4px;
    --rmsc-h: 38px;

}
select:focus {
  outline: none;
}

.select{
   color: #9b2423;
  width: 343px;
  cursor: auto;
  height: 35px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
}
input[type=file]::file-selector-button {
  border: none;
  background-color: #d9d9d9;
  height:35px;
    border-right: 1px solid #af1211;
    border-radius:0px
}

input[type=file]::file-selector-button:hover {
  background-color: #d9d9d9;
  border: none;
  border-right: 1px solid #af1211;
}
.add-helicopter-container-fileinput6 {
    color: #9b2423;
    width: 283px !important;
    font-size: 14px;
    font-family: Poppins;
    border-color: #9b2423;
    border-radius: 0px 14px 14px 0px;
    background-color: transparent !important;
    height: 35px;
    border: 1px solid #af1211;
    margin-left: 220px !important;
}
.text {
    color: #9b2423;
/*    width: 343px;*/
    font-size: 14px;
    font-family: Poppins;
    border-color: #9b2423;
    border-radius: 14px 0px 0px 14px;
    background-color: transparent !important;
    height: 35px;
    border: 1px solid #af1211;
    margin-left: 50px !important;

}

.hide-scroll{
    overflow: scroll;
/*    overflow-x: hidden;*/
    
}
.hide-scroll::-webkit-scrollbar {
    color: black;
}
.rmsc.select1.rmsc1 .dropdown-container {
    color: #9b2423;
    width: 343px;
    border-color: #9b2423;
    border-radius: 14px;
    background-color: #d9d9d9;
    font-size: 14px;
    font-family: Poppins;
    height: 35px;
    border: 1px solid #af1211;
}
.rmsc.select1.rmsc1 .dropdown-heading{
     height: 35px;
     font-family: Poppins;
     overflow: hidden;
}
.add-helicopter-container-containernew {
    width: 50%;
    text-align: center;
    margin-right: 125px;
    justify-items: start;
    
}

.custom-file-button input[type=file] {
  margin-left: -2px !important;
}
.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}
.custom-file-button input[type=file]::file-selector-button {
  display: none;
}
.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}
  [type="file"]::-webkit-file-upload-button {
    /* background:  rgba(158, 36, 35, 0.77); */
    border: 2px solid rgba(158, 36, 35, 0.77);
    border-radius:15px;
    /* color: #fff; */
    cursor: pointer;
    font-size: 12px;
    outline: none;
    padding: 9px 9px;
    text-transform: uppercase;
    height: 38px;
    margin-top: -2px;
    /* transition: all 1s ease; */
  }
  
  /* [type="file"]::-webkit-file-upload-button:hover {
    background: #fff;
    border: 2px solid #535353;
    color: #000;
  } */
  
  /* GENERAL STYLING OF PAGE — NOT APPLICABLE TO EXAMPLE */

