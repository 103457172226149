.navbar-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-navbar {
  width: 100%;
  height: 83px;
  display: flex;
  z-index: 10;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #9b2423;
}
.navbar-navabar-icon-box {
  gap: var(--dl-space-space-unit);
  width: 635px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.navbar-navlink {
  display: contents;
}
.navbar-logo {
  width: 70%;
  height: 50%;
  text-decoration: none;
}
.navbar-logo1 {
  width: 35%;
  height: 60%;
}
.navbar-links-box {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.navbar-map {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.navbar-text:hover {
/*  transition: all 0.3s ease-in;*/
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-notifications {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-text01 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
  text-transform: capitalize;
}
.navbar-language {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
  text-transform: capitalize;
  background:rgb(155, 36, 35)
}
.navbar-text01:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-notification-number {
  top: 8px;
  flex: 0 0 auto;
  left: 199px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.navbar-text02 {
  color: rgb(155, 36, 35);
  width: 100%;
  font-size: 11px;
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 700;
}
.navbar-help-center {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-text03 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.navbar-text03:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-mobile-menu {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-icon {
  width: 24px;
  height: 24px;
}
.navbar-profile-icon-dropdown {
  top: 48px;
  left: -63px;
  width: 178px;
  height: 165px;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.navbar-map1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-navlink2 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.navbar-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
}
.navbar-notifications1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-text06 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-transform: capitalize;
}
.navbar-container01 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-help-center {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  /* border-width: 1px; */
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-text08 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.navbar-profile-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-image {
  width: 37px;
  height: 36px;
  object-fit: cover;
}
.navbar-profile-icon-dropdown1 {
  top: 47px;
  left: -62px;
  width: 178px;
  height: auto;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.navbar-profile {
  cursor: pointer;
  height: 45.2px;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-text09 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.navbar-manage-helicopter {
  cursor: pointer;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-navlink3 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.navbar-user-management {
  cursor: pointer;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-navlink4 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;

}
.navbar-help-center-manage {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle11:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-text10 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-transform: capitalize;
  
}
.navbar-logout {
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.navbar-dropdown-toggle12 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-text11 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.navbar-profile-icon2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle13 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-image1 {
  width: 37px;
  height: 36px;
  object-fit: cover;
}
.navbar-profile-icon-dropdown2 {
  top: 47px;
  left: -62px;
  width: 178px;
  height: auto;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.navbar-profile1 {
  cursor: pointer;
  height: 45.2px;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle14 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle14:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-text12 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.navbar-manage-helicopter1 {
  cursor: pointer;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle15 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle15:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-navlink6 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.navbar-user-management1 {
  cursor: pointer;
  display: none;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle16 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle16:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-navlink7 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.navbar-help-center-manage1 {
  cursor: pointer;
  display: none;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.navbar-dropdown-toggle17 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-dropdown-toggle17:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-text13 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.navbar-logout1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.navbar-dropdown-toggle18 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle18:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.navbar-text14 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.navbar-container02 {
  top: 0;
  left: 0;
  right: 0;
  width: 500px;
  bottom: 0;
  height: 500px;
  margin: auto auto;
  display: flex;
  padding: 18px;
  z-index: 101;
  position: fixed;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  background-color: #F3F3F3;
}
.navbar-container03 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-close-button {
  top: 4px;
  right: 4px;
  width: 36px;
  height: 36px;
  margin: auto;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  background-color: #9E2423;
}
.navbar-close-button:hover {
  background-color: rgba(158, 36, 35, 0.8);
}
.navbar-icon2 {
  fill: #ffffff;
  width: 25px;
  height: 25px;
}
.navbar-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DB6A69;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #D9D9D9;
}
.navbar-comapany-details {
  flex: 0 0 auto;
  width: 100%;
  height: 117px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.navbar-text15 {
  color: #9E2423;
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.navbar-input-feilds {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  height: 192px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.navbar-textinput1 {
  color: #9b2423;
  width: 343px;
  height: 35px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
}
.navbar-textinput2 {
  color: #9b2423;
  width: 343px;
  height: 35px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
}
.navbar-textinput3 {
  color: #9b2423;
  width: 343px;
  height: 35px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
}
.navbar-done-button {
  width: 100%;
  height: 159px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.navbar-container05 {
  flex: 0 0 auto;
  width: 311px;
  height: 44px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #ffffff;
  border-width: 0.5px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: #9E2423;
}
.navbar-container05:hover {
  background-color: rgba(158, 36, 35, 0.73);
}
.navbar-container05:active {
  box-shadow: 0px 0px 0px 0px #000000;
}
.navbar-text16 {
  color: #ffffff;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}

.hide-scroll{
    overflow: scroll;
    overflow-x: hidden;
    background: rgb(155,36,35);
    
}
.hide-scroll::-webkit-scrollbar {
    background: rgb(155,36,35);
    color: black;
}
.navbar-container06 {
  style:none;
  gap: 14px;
  top: 59px;
  right: 165px;
  width: 369px;
  height: 371px;
  /*overflow-y:scroll;
  overflow-x: hidden;*/
  display: flex;
  z-index: 101;
  position: absolute;
  align-items: center;
  padding-top: 12px;
  padding-left: 19px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 12px;
  flex-direction: column;
  padding-bottom: 12px;
  justify-content: flex-start;
  background-color: #9E2423;
}
.navbar-n-container {
  flex: 0 0 auto;
  width: 343px;
  height: 75px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  flex-direction: row;
  justify-content: flex-start;
}
.navbar-n-container:hover {
  border-color: rgba(255, 255, 255, 0.54);
}
.navbar-container07 {
  gap: var(--dl-space-space-halfunit);
  width: 272px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.navbar-text17 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.navbar-text18 {
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
}
.navbar-container08 {
  gap: 5px;
  width: 139px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.navbar-text22 {
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.navbar-container09 {
  width: 50px;
  height: 38px;
  display: flex;
  padding: 2px;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #731414;
}
.navbar-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.navbar-n-container1 {
  flex: 0 0 auto;
  width: 343px;
  height: 75px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  flex-direction: row;
  justify-content: flex-start;
}
.navbar-n-container1:hover {
  border-color: rgba(255, 255, 255, 0.54);
}
.navbar-container11 {
  gap: var(--dl-space-space-halfunit);
  width: 272px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.navbar-text23 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.navbar-text24 {
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
}
.navbar-container12 {
  gap: 5px;
  width: 69px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.navbar-text28 {
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.navbar-container13 {
  width: 50px;
  height: 38px;
  display: flex;
  padding: 2px;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #731414;
}
.navbar-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-size: contain;
/*  background-image: url('https://iili.io/HYla7Hv.png');*/
  background-repeat: no-repeat;
  background-position: center;
}
.navbar-n-container2 {
  flex: 0 0 auto;
  width: 343px;
  height: 75px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  flex-direction: row;
  justify-content: flex-start;
}
.navbar-n-container2:hover {
  border-color: rgba(255, 255, 255, 0.54);
}
.navbar-container15 {
  gap: var(--dl-space-space-halfunit);
  width: 272px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.navbar-text29 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.navbar-text30 {
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
}
.navbar-container16 {
  gap: 5px;
  width: 69px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.navbar-text34 {
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.navbar-container17 {
  width: 50px;
  height: 38px;
  display: flex;
  padding: 2px;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #731414;
}
.navbar-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-size: contain;
/*  background-image: url('https://iili.io/HYla7Hv.png');*/
  background-repeat: no-repeat;
  background-position: center;
}
.navbar-n-container3 {
  flex: 0 0 auto;
  width: 343px;
  height: 75px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  flex-direction: row;
  justify-content: flex-start;
}
.navbar-n-container3:hover {
  border-color: rgba(255, 255, 255, 0.54);
}
.navbar-container19 {
  gap: var(--dl-space-space-halfunit);
  width: 272px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.navbar-text35 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.navbar-text36 {
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
}
.navbar-container20 {
  gap: 5px;
  width: 69px;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.navbar-text40 {
  color: #ffffff;
  font-size: 10px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.navbar-container21 {
  width: 50px;
  height: 38px;
  display: flex;
  padding: 2px;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #731414;
}
.navbar-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-size: contain;
/*  background-image: url('https://iili.io/HYla7Hv.png');*/
  background-repeat: no-repeat;
  background-position: center;
}
.navbar-root-class-name {
  top: 0px;
  left: 0px;
  position: absolute;
}
.navbar-root-class-name1 {
  top: 0px;
  left: 0px;
  position: static;
}

@media(max-width: 991px) {
  .navbar-logo1 {
    display: none;
  }
  .navbar-map {
    display: none;
  }
  .navbar-notifications {
    display: none;
  }
  .navbar-notification-number {
    display: none;
  }
  .navbar-help-center {
    display: none;
  }
  .navbar-mobile-menu {
    display: inline-block;
  }
  .navbar-icon {
    fill: #ffffff;
    width: 28px;
    height: 28px;
  }
  .navbar-profile-icon-dropdown {
    top: 45px;
    left: -63px;
    height: auto;
  }
  .navbar-dropdown-toggle04 {
    height: 60px;
  }
  .navbar-dropdown-toggle05 {
    height: 60px;
    position: relative;
  }
  .navbar-container01 {
    top: 8px;
    left: 101px;
    width: 20px;
    height: 20px;
    position: absolute;
    align-items: center;
    border-width: 0px;
    border-radius: 50%;
    justify-content: center;
    background-color: #ffffff;
  }
  .navbar-text07 {
    color: #9b2423;
    width: 100%;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 700;
  }
  .navbar-dropdown-toggle06 {
    height: 60px;
  }
  .navbar-container06 {
    top: 53px;
    right: 253px;
  }
}
@media(max-width: 767px) {
  .navbar-navbar {
    top: 0px;
    left: 0px;
    position: relative;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-logo1 {
    display: none;
  }
  .navbar-map {
    display: none;
  }
  .navbar-notifications {
    display: none;
  }
  .navbar-help-center {
    display: none;
  }
  .navbar-mobile-menu {
    display: inline-block;
  }
  .navbar-dropdown-toggle03 {
    align-items: center;
    justify-content: center;
  }
  .navbar-icon {
    fill: #ffffff;
    width: 32px;
    height: 32px;
  }
  .navbar-profile-icon-dropdown {
    top: 48px;
    left: -125px;
    height: auto;
  }
  .text-link {
    color: #FFFFFF;
  text-decoration: none;
}
  .navbar-navlink2 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .navbar-text06 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .navbar-text08 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .navbar-container06 {
    top: 250px;
    right: 126px;
  }
  .navbar-root-class-name1 {
    top: 0px;
    left: 0px;
    height: var(--dl-size-size-medium);
    position: static;
  }
  .navbar-root-class-name2 {
    top: 0px;
    left: 0px;
    position: static;
  }
}
@media(max-width: 479px) {
  .navbar-navbar {
    padding: var(--dl-space-space-unit);
  }
}
@media(max-width: 464px) {
  .navbar-navbar {
    padding-left: 29px;
    padding-right: 16px;
  }
  .navbar-logo {
    width: 170px;
    height: 32px;
  }
  .navbar-links-box {
    gap: 2px;
    width: 172px;
  }
  .navbar-profile-icon-dropdown {
    height: auto;
  }
  .navbar-profile-icon-dropdown1 {
    top: 51px;
    left: -100px;
    width: auto;
    height: auto;
  }
  .navbar-profile-icon-dropdown2 {
    top: 51px;
    left: -100px;
    width: auto;
    height: auto;
  }
  .navbar-container02 {
    width: 100%;
  }
  .navbar-textinput1 {
    width: 280px;
  }
  .navbar-textinput2 {
    width: 280px;
  }
  .navbar-textinput3 {
    width: 280px;
  }
  .navbar-container05 {
    width: 270px;
  }
  .navbar-container06 {
    top: 249px;
    left: -20px;
  }
}
