.helicopter-icon-black-container {
  width: auto;
  height: auto;
  display: flex;
  z-index: 99;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-black-container1 {
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-black-container2 {
  width: 129px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #282829;
}
.helicopter-icon-black-image {
  width: 94px;
  object-fit: cover;
}
.helicopter-icon-black-icon {
  fill: #282829;
  left: 42px;
  width: 45px;
  bottom: -34px;
  height: 56px;
  z-index: 98;
  position: absolute;
}
.helicopter-icon-black-root-class-name {
  top: 152px;
  left: 587px;
  position: absolute;
}
@media(max-width: 767px) {
  .helicopter-icon-black-root-class-name {
    top: 344px;
    right: 195px;
  }
}
@media(max-width: 464px) {
  .helicopter-icon-black-root-class-name {
    top: 477px;
    left: 89px;
  }
}
