.profile-pop-up-container {
  width: 500px;
  height: 500px;
  display: flex;
  padding: 18px;
  z-index: 101;
  position: relative;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #F3F3F3;
}
.profile-pop-up-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.profile-pop-up-close-button {
  top: 4px;
  right: 4px;
  width: 36px;
  height: 36px;
  margin: auto;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: center;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  background-color: #9E2423;
}
.profile-pop-up-close-button:hover {
  background-color: rgba(158, 36, 35, 0.8);
}
.profile-pop-up-icon {
  fill: #ffffff;
  width: 25px;
  height: 25px;
}
.profile-pop-up-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #DB6A69;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #D9D9D9;
}
.profile-pop-up-comapany-details {
  flex: 0 0 auto;
  width: 100%;
  height: 117px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.profile-pop-up-text {
  color: #9E2423;
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.profile-pop-up-input-feilds {
  gap: 16px;
  flex: 0 0 auto;
  width: 100%;
  height: 192px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.profile-pop-up-textinput1 {
  color: #9b2423;
  width: 343px;
  height: 35px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
}
.profile-pop-up-textinput2 {
  color: #9b2423;
  width: 343px;
  height: 35px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
}
.profile-pop-up-textinput3 {
  color: #9b2423;
  width: 343px;
  height: 35px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-width: 1px;
  border-radius: 14px;
  background-color: transparent;
}
.profile-pop-up-done-button {
  width: 100%;
  height: 159px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.profile-pop-up-container3 {
  flex: 0 0 auto;
  width: 311px;
  height: 44px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #ffffff;
  border-width: 0.5px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: #9E2423;
}
.profile-pop-up-container3:hover {
  background-color: rgba(158, 36, 35, 0.73);
}
.profile-pop-up-container3:active {
  box-shadow: 0px 0px 0px 0px #000000;
}
.profile-pop-up-text1 {
  color: #ffffff;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.profile-pop-up-root-class-name {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  position: absolute;
}
@media(max-width: 464px) {
  .profile-pop-up-textinput1 {
    width: 280px;
  }
  .profile-pop-up-textinput2 {
    width: 280px;
  }
  .profile-pop-up-textinput3 {
    width: 280px;
  }
  .profile-pop-up-container3 {
    width: 270px;
  }
}
