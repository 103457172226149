.add-button-and-search-add-button-and-search {
    gap: 30px;
    flex: 0 0 auto;
    width: 100%;
    height: 200px;
    display: flex;
    padding: 40px;
    position: relative;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
  }
  .add-button-and-search-add-button {
    width: 245px;
    height: 40px;
    display: flex;
    transition: 0.3s;
    align-items: center;
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    background-color: #9B2423;
  }
  .add-button-and-search-add-button:hover {
    background-color: rgba(155, 36, 35, 0.81);
  }
  .add-button-and-search-text {
    color: #ffffff;
    font-size: 25px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
  }
  .add-button-and-search-search-box {
    flex: 0 0 auto;
    width: 490px;
    height: 40px;
    display: flex;
    align-items: center;
    padding-top: 2px;
    border-color: #9B2423;
    border-style: solid;
    border-width: 1px;
    padding-left: 17px;
    border-radius: 10px;
    padding-right: 2px;
    flex-direction: row;
    padding-bottom: 2px;
    justify-content: flex-start;
  }
  .add-button-and-search-icon {
    fill: #9B2423;
    width: 24px;
    height: 24px;
  }
  .add-button-and-search-textinput {
    width: 437px;
    height: 34px;
    border-width: 0px;
  }
  .add-button-and-search-container {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 500px;
    bottom: 0px;
    height: 700px;
    margin: auto;
    display: flex;
    padding: 18px;
    z-index: 101;
    position: fixed;
    align-items: flex-start;
    border-radius: 10px;
    flex-direction: column;
    background-color: #F3F3F3;
  }
  .add-button-and-search-close-button {
    top: 4px;
    right: 4px;
    width: 36px;
    height: 36px;
    margin: auto;
    display: flex;
    position: absolute;
    transition: 0.3s;
    align-items: center;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    background-color: #9E2423;
  }
  .add-button-and-search-close-button:hover {
    background-color: rgba(158, 36, 35, 0.8);
  }
  .add-button-and-search-icon2 {
    fill: #ffffff;
    width: 25px;
    height: 25px;
  }
  .add-button-and-search-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-color: #DB6A69;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    background-color: #D9D9D9;
    cursor: pointer;
  }
  .add-button-and-search-comapany-details {
    flex: 0 0 auto;
    width: 100%;
    height: 117px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .add-button-and-search-text1 {
    color: rgb(158, 36, 35);
    font-size: 24px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 700;
  }
  .add-button-and-search-input-feilds {
    gap: 16px;
    width: 100%;
    height: 474px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .add-button-and-search-textinput2 {
    color: #9b2423;
    width: 343px;
    height: 35px;
    font-size: 14px;
    font-family: Poppins;
    border-color: #9b2423;
    border-width: 1px;
    border-radius: 14px;
    background-color: transparent;
  }
  .add-button-and-search-textinput3 {
    color: #9b2423;
    width: 343px;
    height: 35px;
    font-size: 14px;
    font-family: Poppins;
    border-color: #9b2423;
    border-width: 1px;
    border-radius: 14px;
    background-color: transparent;
  }
  .add-button-and-search-textinput4 {
    color: #9b2423;
    width: 343px;
    height: 35px;
    font-size: 14px;
    font-family: Poppins;
    border-color: #9b2423;
    border-width: 1px;
    border-radius: 14px;
    background-color: transparent;
  }
  .add-button-and-search-textinput5 {
    color: #9b2423;
    width: 343px;
    height: 35px;
    font-size: 14px;
    font-family: Poppins;
    border-color: #9b2423;
    border-width: 1px;
    border-radius: 14px;
    background-color: transparent;
  }
  .add-button-and-search-textinput6 {
    color: #9b2423;
    width: 343px;
    height: 35px;
    font-size: 14px;
    font-family: Poppins;
    border-color: #9b2423;
    border-width: 1px;
    border-radius: 14px;
    background-color: transparent;
  }
  .add-button-and-search-done-button {
    width: 100%;
    height: 159px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .selection-class{
    border-width: 1px;
    border-radius: 14px;
    width: 343px;
    height: 36px;
    border-color: #9B2423;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 32px;
    padding-right: 32px;
    color: #9B2423;
    background-color: transparent;

  }
  .add-button-and-search-container2 {
    flex: 0 0 auto;
    width: 311px;
    height: 44px;
    display: flex;
    transition: 0.3s;
    align-items: center;
    border-color: #ffffff;
    border-width: 0.5px;
    border-radius: var(--dl-radius-radius-radius4);
    flex-direction: column;
    justify-content: center;
    background-color: #9E2423;
  }
  .add-button-and-search-container2:hover {
    background-color: rgba(158, 36, 35, 0.73);
  }
  .add-button-and-search-container2:active {
    box-shadow: 0px 0px 0px 0px #000000;
  }
  .add-button-and-search-text2 {
    color: #ffffff;
    font-style: normal;
    font-family: Poppins;
    font-weight: 600;
  }
  .add-button-and-search-root-class-name {
    height: auto;
  }
  @media(max-width: 767px) {
    .add-button-and-search-add-button-and-search {
      height: 315px;
    }
    .add-button-and-search-search-box {
      width: 390px;
    }
    .add-button-and-search-container {
      width: 400px;
    }
  }
  @media(max-width: 464px) {
    .selection-class{
      width: 280px;
    }
    .add-button-and-search-search-box {
      width: 100%;
    }
    .add-button-and-search-container {
      width: auto;
      height: 592px;
    }
    .add-button-and-search-textinput2 {
      width: 280px;
    }
    .add-button-and-search-textinput3 {
      width: 280px;
    }
    .add-button-and-search-textinput4 {
      width: 280px;
    }
    .add-button-and-search-textinput5 {
      width: 280px;
    }
    .add-button-and-search-textinput6 {
      width: 280px;
    }
    .add-button-and-search-container2 {
      width: 270px;
    }
  }
  