.manage-helicopter-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #D9D9D9;
}
.manage-helicopter-navbar {
  width: 100%;
  height: 83px;
  display: none;
  z-index: 100;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #9b2423;
}
.manage-helicopter-navabar-icon-box {
  gap: var(--dl-space-space-unit);
  width: 635px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.manage-helicopter-navlink {
  display: contents;
}
.manage-helicopter-logo {
  width: 70%;
  height: 50%;
  text-decoration: none;
}
.manage-helicopter-logo1 {
  width: 35%;
  height: 60%;
}
.manage-helicopter-links-box {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  justify-content: center;
}
.manage-helicopter-map {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.manage-helicopter-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.manage-helicopter-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.manage-helicopter-text:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-notifications {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.manage-helicopter-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-text01 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.manage-helicopter-text01:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-help-center {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.manage-helicopter-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-text02 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.manage-helicopter-text02:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-mobile-menu {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.manage-helicopter-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-icon {
  width: 24px;
  height: 24px;
}
.manage-helicopter-profile-icon-dropdown {
  top: 48px;
  left: -63px;
  width: 178px;
  height: 165px;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.manage-helicopter-map1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.manage-helicopter-text03 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.manage-helicopter-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
}
.manage-helicopter-notifications1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.manage-helicopter-text06 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.manage-helicopter-help-center {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.manage-helicopter-text07 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.manage-helicopter-profile-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.manage-helicopter-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-image {
  width: 37px;
  height: 36px;
  object-fit: cover;
}
.manage-helicopter-profile-icon-dropdown1 {
  top: 47px;
  left: -62px;
  width: 178px;
  height: 231px;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.manage-helicopter-profile {
  cursor: pointer;
  height: 64px;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.manage-helicopter-text08 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.manage-helicopter-manage-helicopter {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.manage-helicopter-navlink2 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.manage-helicopter-user-management {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.manage-helicopter-navlink3 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.manage-helicopter-help-center-manage {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.manage-helicopter-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-dropdown-toggle11:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.manage-helicopter-text09 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.manage-helicopter-logout {
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.manage-helicopter-dropdown-toggle12 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.manage-helicopter-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.manage-helicopter-text10 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.manage-helicopter-container1 {
  width: 100%;
  height: 145px;
  display: flex;
  padding: 48px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.manage-helicopter-container2 {
  flex: 0 0 auto;
  width: 178px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: #9B2423;
}
.manage-helicopter-text11 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
  cursor: pointer;
}
.manage-helicopter-container3 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  /* border: 2px solid black; */
}

.fire-marker-container {
  position: relative;
  width: 32px;
  height: 32px;
}

.fire-marker {
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.fire-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}



.delete-fire-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #ff4136;
  border-radius: 50%;
  color: #ff4136;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.delete-fire-btn:hover {
  background-color: #ff4136;
  color: white;
}

.report-fire-btn {
  background-color: #9b2423;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


.report-fire-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.fire-marker.selected-fire {
  border: 3px solid yellow;
  box-shadow: 0 0 10px yellow;
}

.mapboxgl-marker {
  transform: translate(-50%, -50%);
}

.mapboxgl-popup-content {
  padding: 10px;
  border-radius: 20px; /* Match the circular shape of the cluster */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  background-color: #51bbd6; /* Match the cluster background color */
  color: #1c3351; /* Match the cluster text color */
  overflow: hidden;
}

.popup-content::-webkit-scrollbar {
  width: 8px;
}

.popup-content::-webkit-scrollbar-track {
  background: #51bbd6;
}

.popup-content::-webkit-scrollbar-thumb {
  background-color: #1c3351;
  border-radius: 4px;
  border: 2px solid #51bbd6;
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background-color: #152640;
}


/* For Firefox */
.popup-content {
  scrollbar-width: thin;
  scrollbar-color: #1c3351 #51bbd6;
}

/* Ensure the border-radius is maintained */
.mapboxgl-popup-content {
  border-radius: 8px !important;
}

.popup-content {
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
/* Style for the links inside the popup */
/* .popup-content a {
  color: #ffffff;
  text-decoration: none;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
} */

/* Popup tip styling */
.mapboxgl-popup-tip {
  border-top-color: #51bbd6 !important; /* Match the background color */
  margin-top: -10px; /* Adjust this to fine-tune the tip position */
}

/* Positioning the popup above the cluster */
.mapboxgl-popup {
  margin-bottom: 15px;
}

.mapboxgl-popup-close-button {
  display: none;
}


.helicopter-link {
  color: #1c3351 !important;;
  text-decoration: none;
  font-weight: bold;
}

.helicopter-link:hover {
  text-decoration: underline;
}



.mapboxgl-popup-tip {
  border: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #9b2423 transparent;
}

/* Adjust tip position for different anchor positions */
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #51bbd6;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #51bbd6;
  /* border-bottom-color: transparent; */
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #9b2423;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #9b2423;
}
@media(max-width: 991px) {
  .manage-helicopter-logo1 {
    display: none;
  }
  .manage-helicopter-map {
    display: none;
  }
  .manage-helicopter-notifications {
    display: none;
  }
  .manage-helicopter-help-center {
    display: none;
  }
  .manage-helicopter-mobile-menu {
    display: inline-block;
  }
  .manage-helicopter-icon {
    fill: #ffffff;
    width: 28px;
    height: 28px;
  }
  .manage-helicopter-profile-icon-dropdown {
    height: 100px;
  }
}
@media(max-width: 1299px) {
  .manage-helicopter-container3 {
    /* gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    display: grid; */
    grid-template-columns: auto auto;
    /* padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: row;
    justify-content: center; */
    /* border: 2px solid black; */
  }
  .manage-helicopter-navbar {
    top: 0px;
    left: 0px;
    position: absolute;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .manage-helicopter-logo1 {
    display: none;
  }
  .manage-helicopter-map {
    display: none;
  }
  .manage-helicopter-notifications {
    display: none;
  }
  .manage-helicopter-help-center {
    display: none;
  }
  .manage-helicopter-mobile-menu {
    display: inline-block;
  }
  .manage-helicopter-dropdown-toggle03 {
    align-items: center;
    justify-content: center;
  }
  .manage-helicopter-icon {
    fill: #ffffff;
    width: 32px;
    height: 32px;
  }
  .manage-helicopter-profile-icon-dropdown {
    top: 48px;
    left: -125px;
    height: 101px;
  }
  .manage-helicopter-text03 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .manage-helicopter-text06 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .manage-helicopter-text07 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
}
@media(max-width: 871px){
  .manage-helicopter-container3 {
    /* gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    display: grid; */
    grid-template-columns: auto;
    /* padding: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: row;
    justify-content: center; */
    /* border: 2px solid black; */
  }
}
@media(max-width: 479px) {
  .manage-helicopter-navbar {
    padding: var(--dl-space-space-unit);
  }
}
@media(max-width: 464px) {
  .manage-helicopter-navbar {
    padding-left: 29px;
    padding-right: 16px;
  }
  .manage-helicopter-logo {
    width: 170px;
    height: 32px;
  }
  .manage-helicopter-links-box {
    gap: 2px;
    width: 172px;
  }
  .manage-helicopter-profile-icon-dropdown1 {
    top: 51px;
    left: -100px;
    height: 165px;
  }
}
