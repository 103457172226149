.manage-helicopter-card-container {
  width: 430px;
  height: 664px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: center;
}

.select-option-status{
  background-color: black;
  color: white;
  border-radius: 8px;
  padding:5px;
  width: 150px;
  overflow: auto;
  position: absolute; /* Add this */
  top: 30%; /* Add this */
  left: 0; /* Add this */
  z-index: 1; /* Add this */
}

.selector {
  background-color: #252525;
  color: #fff;
  border: none;
}
.select-option-status option{
border: 2px solid transparent;
}
.select-option-status1{
  background-color: #009A17;

}

.hide-scroll{
    overflow: scroll;
    overflow-x: hidden;
    background: rgb(155,36,35);
    
}
.hide-scroll::-webkit-scrollbar {
    background: rgb(155,36,35);
    color: black;
}
.select-option-status1:hover{
  background-color: #009A17;
  border: 2px solid;
}

.select-option-status2{
  background-color: #009A17;
}
.select-option-status2:hover{
  background-color: #009A17;
  border: 2px solid;
}

.select-option-status3{
  background-color: #F6BE00;
}
.select-option-status3:hover{
  background-color: #F6BE00;
  border: 2px solid;
}

.select-option-status4{
  background-color: #F6BE00;
}
.select-option-status4:hover{
  background-color: #F6BE00;
  border: 2px solid;
}

.select-option-status5{
  background-color: #F6BE00;
}
.select-option-status5:hover{
  background-color: #F6BE00;
  border: 2px solid;
}

.select-option-status6{
  background-color: #9B2423;
}
.select-option-status6:hover{
  background-color: #9B2423;
  border: 2px solid;
}

.select-option-status7{
  background-color: #282829;
}
.select-option-status7:hover{
  background-color: #282829;
  border: 2px solid;
}

.select-option-status8{
  background-color: #0000FF;
}
.select-option-status8:hover{
  background-color: #0000FF;
  border: 2px solid;
}
/*////////////////////////////////////////////////////////////////////////////////*/
.status1 {
  background: #009A17;
}

/*//////////////////////////////////////////////////////////////////////////////*/

.manage-helicopter-card-image {
  width: 100%;
  min-height: 226px;
  height: 226px;
  object-fit: cover;
  border-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.manage-helicopter-card-content2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
}
.manage-helicopter-card-container01 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 2px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}

.manage-helicopter-card-text02 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.manage-helicopter-card-container04 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.manage-helicopter-card-container05 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.manage-helicopter-card-container06 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.manage-helicopter-card-text03 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.manage-helicopter-card-text04 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
  min-height: 75PX;
}
.manage-helicopter-card-text-number {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.manage-helicopter-card-container07 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.manage-helicopter-card-text05 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.manage-helicopter-card-text06 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.manage-helicopter-card-container08 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.manage-helicopter-card-container09 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.manage-helicopter-card-text07 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.manage-helicopter-card-text09 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.manage-helicopter-card-text10 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.manage-helicopter-card-container10 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}


.manage-helicopter-card-text11 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.manage-helicopter-card-text12 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.manage-helicopter-card-buttons {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.manage-helicopter-card-container11 {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.manage-helicopter-card-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 42px;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #9B2423;
}
.manage-helicopter-card-container12:hover {
  background-color: rgba(155, 36, 35, 0.8);
}
.manage-helicopter-card-text13 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
}

.add-helicopter-container-textinput7 {
  color: #9b2423;
  width: 775px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

.manage-helicopter-card-text, .manage-helicopter-card-text12 {
  margin-right: var(--dl-space-space-unit);
}

.toggle-switch {
  position: relative;
}


.helicopter-marker.status1::before {
  border-top: 15px solid #009A17;
}

.status2 {
  background: #009A17;
}

.helicopter-marker.status2::before {
  border-top: 15px solid #009A17;
}

.status3 {
  background: #F6BE00;
}

.helicopter-marker.status3::before {
  border-top: 15px solid #F6BE00;
}
.status4 {
  background: #F6BE00;
}

.helicopter-marker.status4::before {
  border-top: 15px solid #F6BE00;
}

.status5 {
  background: #F6BE00;
}

/*//////////////////////////////////////////////////////////////////////////////*/

.manage-helicopter-card-container02 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.manage-helicopter-card-container03 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.manage-helicopter-card-text {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}
.manage-helicopter-card-text01 {
      min-height: 70px;
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.manage-helicopter-card-einsatzbereit-in {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.manage-helicopter-card-container10 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}

.manage-helicopter-card-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit) 0;
  align-items: center;
  flex-direction: row;
  justify-content: space-between; /* This will push the toggle to the right */

}

.manage-helicopter-card-container14 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit) 0;
  align-items: center;
  padding-right: 20px; 
  flex-direction: row;
  justify-content: space-between; /* This will push the toggle to the right */

}

.manage-helicopter-card-text11 {
  font-size: 18px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
}

.toggle-switch {
  position: relative;
}
.manage-helicopter-card-container {
  width: 430px;
  height: 664px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: center;
}

.helicopter-marker.status5::before {
  border-top: 15px solid #F6BE00;
}
.status6 {
  background: #9B2423;
}

.helicopter-marker.status6::before {
  border-top: 15px solid #9B2423;
}

.status7 {
  background: #282829;
}

.helicopter-marker.status7::before {
  border-top: 15px solid #282829;
}

.status8 {
  background: #0000FF;
}

.helicopter-marker.status8::before {
  border-top: 15px solid #0000FF;
}

/*//////////////////////////////////////////////////////////////////////////////*/

.add-helicopter-container-textinput7 {
  color: #9b2423;
  width: 775px;
  font-size: 14px;
  font-family: Poppins;
  border-color: #9b2423;
  border-radius: 14px;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

.manage-helicopter-card-text, .manage-helicopter-card-text12 {
  margin-right: var(--dl-space-space-halfunit);
}

.toggle-switch {
  position: relative;
  width: 65px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: "ON";
  text-transform: uppercase;
  padding-left: 10px;
  background-color: #009A17;
  color: #fff;
}
.toggle-switch-inner:after {
  content: "OFF";
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 33px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}

@media(max-width: 768px) {

  .manage-helicopter-card-text04 {
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    min-height: auto;
  }

  .toggle-switch {
    width: 60px; /* Reduced from 65px */
  }

  .toggle-switch-inner:before,
  .toggle-switch-inner:after {
    height: 26px; /* Reduced from 34px */
    line-height: 26px; /* Reduced from 34px */
    font-size: 12px; /* Reduced from 14px */
  }

  .toggle-switch-switch {
    width: 18px; /* Reduced from 24px */
    right: 31px; /* Adjusted from 33px */
  }
 
  .manage-helicopter-card-container {
    width: 100%;
    height: auto;
    bottom: 0;
    position: fixed;
    z-index: 1000;
    padding: 10px;
    border-radius: 20px;
    margin-top: 28px;
    font-size: 14px !important;


  }

  .manage-helicopter-card-text01 {
    min-height: 50px;
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
 }
  .manage-helicopter-card-root-class-name {
    width: 100%;
  }

  .manage-helicopter-card-image {
    display: none;
  }

  .manage-helicopter-card-content2 {
    padding: 1px;
  }

  .manage-helicopter-card-container01,
  .manage-helicopter-card-container02,
  .manage-helicopter-card-container03,
  .manage-helicopter-card-container04,
  .manage-helicopter-card-container05,
  .manage-helicopter-card-container07,
  .manage-helicopter-card-container08,
  .manage-helicopter-card-container09,
  .manage-helicopter-card-container10,
  .manage-helicopter-card-container13 {
    padding: inherit;
    margin: 0;
  }

  .manage-helicopter-card-text {
    font-size: 14px !important;
  }

  .manage-helicopter-card-text01,
  .manage-helicopter-card-text02,
  .manage-helicopter-card-text03,
  .manage-helicopter-card-text04,
  .manage-helicopter-card-text05,
  .manage-helicopter-card-text06,
  .manage-helicopter-card-text07,
  .manage-helicopter-card-text10,
  .manage-helicopter-card-text11,
  .manage-helicopter-card-text12 {
    font-size: 14px !important;
  }

  .manage-helicopter-card-buttons {
    flex-direction: column;
  }

  .manage-helicopter-card-container12 {
    width: 100%;
  }

  .sidebar-content {
    width: 80%;
    height: auto;
    padding: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  .sidebar {
    padding: 0;
    width: 100vw; /* Adjusted to be relative to the viewport width */
    height: 5%;
    transition: transform 1s;
    z-index: 10;
    font-size: 14px !important;

  }

  .sidebar .select-option-status {
    color: white;
    font-size: 14px !important;
    border-radius: 5px;
    position: absolute;
    margin-top: 15px;
    width: 100px;
    overflow: auto;
  }
}

