.helicopter-icon-stack-hover-container {
  width: auto;
  height: auto;
  display: flex;
  z-index: 99;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-stack-hover-container01 {
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.helicopter-icon-stack-hover-container02 {
  gap: var(--dl-space-space-unit);
  width: 129px;
  height: 129px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #009A17;
}
.helicopter-icon-stack-hover-text {
  color: rgb(255, 255, 255);
  font-size: 21px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.helicopter-icon-stack-hover-container03 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.helicopter-icon-stack-hover-text01 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.helicopter-icon-stack-hover-text02 {
  color: rgb(220, 220, 220);
  font-size: 21px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
}
.helicopter-icon-stack-hover-container04 {
  gap: var(--dl-space-space-unit);
  width: 129px;
  height: 129px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #F6BE00;
}
.helicopter-icon-stack-hover-text03 {
  color: rgb(255, 255, 255);
  font-size: 21px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.helicopter-icon-stack-hover-container05 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.helicopter-icon-stack-hover-text04 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.helicopter-icon-stack-hover-text05 {
  color: #dcdcdc;
  font-size: 21px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
}
.helicopter-icon-stack-hover-container06 {
  gap: var(--dl-space-space-unit);
  width: 129px;
  height: 129px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #9B2423;
}
.helicopter-icon-stack-hover-text06 {
  color: rgb(255, 255, 255);
  font-size: 21px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.helicopter-icon-stack-hover-container07 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.helicopter-icon-stack-hover-text07 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.helicopter-icon-stack-hover-text08 {
  color: #dcdcdc;
  font-size: 21px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
}
.helicopter-icon-stack-hover-container08 {
  gap: var(--dl-space-space-unit);
  width: 129px;
  height: 129px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #282829;
}
.helicopter-icon-stack-hover-text09 {
  color: rgb(255, 255, 255);
  font-size: 21px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.helicopter-icon-stack-hover-container09 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.helicopter-icon-stack-hover-text10 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.helicopter-icon-stack-hover-text11 {
  color: rgb(255, 255, 255);
  display: none;
  font-size: 21px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.helicopter-icon-stack-hover-icon {
  fill: #282829;
  left: 42px;
  width: 45px;
  bottom: -34px;
  height: 56px;
  z-index: 98;
  position: absolute;
}
.helicopter-icon-stack-hover-root-class-name {
  top: 223px;
  left: 219px;
  position: absolute;
}
