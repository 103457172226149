.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;

}
.login-signin-main-panel {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-unit);
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  background-size: cover;
  justify-content: center;
  background-image: url('/playground_assets/bg.png')
}
.about-us {
  margin-top: 540px;
  font-family: Poppins;
  font-weight: 700;

}
.login-transparent-box {
  width: 600px;
  height: 600px;
  display: flex;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  background-image: linear-gradient(315deg, rgba(105, 149, 196, 0.63) 0.00%,rgba(243, 243, 243, 0.3) 100.00%);
}
.login-welcome-text {
  top: 96px;
  left: 69px;
  color: rgba(1, 51, 80, 1);
  height: auto;
  position: absolute;
  font-size: 60px;
  align-self: auto;
  font-style: Bold;
  text-align: left;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}

.login-welcome-text-otp {
  top: 158px;
  left: 69px;
  width: 450px;
  align-items: center;
  color: rgba(1, 51, 80, 1);
  height: auto;
  position: absolute;
  font-size: 20px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.login-welcome-text-otp-title {
  top: 88px;
  left: 69px;
  width: 480px;
  color: rgba(1, 51, 80, 1);
  height: auto;
  position: absolute;
  font-size:35px;
  align-self: auto;
  font-style: Bold;
  text-align: center;
  font-family: Poppins;
  font-weight: 700;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.login-little-header-text {
  top: 177px;
  left: 79px;
  color: rgba(1, 51, 80, 1);
  height: auto;
  position: absolute;
  font-size: 28px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.login-company-id {
  top: 269px;
  left: 80px;
  width: 441px;
  height: 38px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.login-icon1 {
  top: 7px;
  left: 0px;
  width: 18px;
  height: 24px;
  position: absolute;
}
.login-textinput {
  top: 0px;
  left: 35px;
  color: #605d5d;
  width: 404px;
  height: 38px;
  position: absolute;
  font-size: 12px;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 8px;
  background-color: #ffffff;
}
.login-pass-word {
  top: 339px;
  left: 79px;
  width: 442px;
  height: 38px;
  display: flex;
  padding: 0;
  position: absolute;
  align-self: auto;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0px 0px 0px 0px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: transparent;
}
.login-icon2 {
  top: 9px;
  left: 0px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.login-textinput1 {
  color: #605d5d;
  right: 1px;
  width: 404px;
  bottom: 0px;
  height: 38px;
  position: absolute;
  font-size: 12px;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 8px;
  background-color: #ffffff;
}

.forgot-pass {
  right: -210px;
  width: 404px;
  bottom: 0px;
  height: 38px;
  position: absolute;
  bottom:180px;
  font-size: 12px;
  font-family: Poppins;
  
}

.login-navlink {
  display: contents;
}
.login-signin-button {
  top: 464px;
  left: 83px;
  width: 438px;
  height: 50px;
  display: flex;
  position: absolute;
  transition: 0.3s;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: #ffffff;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  text-decoration: none;
  background-color: #9b2423;
}
.login-signin-button:hover {
  background-color: #801c1b;
}
.login-signin-button:active {
  background-color: rgba(155, 36, 35, 0.73);
}
.login-text {
  top: 8px;
  left: 158px;
  color: rgba(255, 255, 255, 1);
  height: auto;
  position: absolute;
  font-size: 24px;
  align-self: auto;
  font-style: SemiBold;
  text-align: left;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-stretch: normal;
  margin-right: 0;
  margin-bottom: 0;
  text-decoration: none;
}
@media(max-width: 767px) {
  .login-signin-main-panel {
    background-image: url("/playground_assets/bg.png");
  }
  .login-transparent-box {
    width: 389px;
    height: 429px;
  }
  .login-welcome-text {
    top: 45px;
    left: 27px;
    color: rgb(1, 51, 80);
    font-size: 40px;
    font-family: Poppins;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
  }
  .login-little-header-text {
    top: 96px;
    left: 29px;
    color: rgb(1, 51, 80);
    font-size: 23px;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
  }
  .login-company-id {
    top: 163px;
    left: 10px;
    width: 437px;
    align-items: center;
    justify-content: center;
  }
  .login-icon1 {
    top: 7px;
    left: 7px;
  }
  .login-textinput {
    left: 36px;
    width: 302px;
    bottom: 0px;
    position: absolute;
    font-size: 12px;
    font-family: Poppins;
    border-width: 0px;
  }
  .login-pass-word {
    top: 212px;
    left: 4px;
  }
  .login-icon2 {
    top: 8px;
    left: 11px;
  }
  .login-textinput1 {
    top: 0px;
    left: 42px;
    width: 302px;
    position: absolute;
    font-size: 12px;
    font-family: Poppins;
    border-width: 0px;
  }
  .login-signin-button {
    top: 312px;
    left: 28px;
    width: 339px;
    height: 50px;
    border-color: #ffffff;
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    background-color: #9b2423;
  }
  .login-text {
    left: -1px;
    width: 100%;
    bottom: 0px;
    height: auto;
    margin: auto;
    text-align: center;
  }
}
@media(max-width: 464px) {
  .login-transparent-box {
    width: 389px;
    height: 429px;
  }
  .login-textinput {
    width: 70vw;
  }
  .login-textinput1 {
    width: 70vw;
  }
  .login-signin-button {
    width: 75vw;
  }
}
