.user-management-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #D9D9D9;
}
.user-management-navbar {
  width: 100%;
  height: 83px;
  display: none;
  z-index: 100;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #9b2423;
}

.table td{
    border: rgb(155,36,35) solid 1px !important;
}
.table th{
    border: rgb(155,36,35)solid 1px !important;
}
.table {
    color: rgb(155,36,35) !important;
}
.button {
    color: #fff;
    border :none;
    background-color: rgb(155,36,35) !important; ;
}
.user-management-navabar-icon-box {
  gap: var(--dl-space-space-unit);
  width: 635px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.user-management-navlink {
  display: contents;
}
.user-management-logo {
  width: 70%;
  height: 50%;
  text-decoration: none;
}
.user-management-logo1 {
  width: 35%;
  height: 60%;
}
.user-management-links-box {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-management-map {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.user-management-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.user-management-text {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.user-management-text:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-notifications {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.user-management-dropdown-toggle01 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-text01 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.user-management-text01:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-help-center {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.action-buttons {
  gap: 4px; /* Adjust this value to your preference */
}
.user-management-dropdown-toggle02 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-text02 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  transition: all 0.3s ease-in;
  font-family: Poppins;
  font-weight: 300;
  margin-right: 4px;
  vertical-align: middle;
}
.user-management-text02:hover {
  transition: all 0.3s ease-in;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-mobile-menu {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.user-management-dropdown-toggle03 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-icon {
  width: 24px;
  height: 24px;
}
.user-management-profile-icon-dropdown {
  top: 48px;
  left: -63px;
  width: 178px;
  height: 165px;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.user-management-map1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-dropdown-toggle04 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-dropdown-toggle04:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.user-management-text03 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
}
.user-management-notifications1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-dropdown-toggle05 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-dropdown-toggle05:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.user-management-text06 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-help-center {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-dropdown-toggle06 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-dropdown-toggle06:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.user-management-text07 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-profile-icon {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.user-management-dropdown-toggle07 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-image {
  width: 37px;
  height: 36px;
  object-fit: cover;
}
.user-management-profile-icon-dropdown1 {
  top: 47px;
  left: -62px;
  width: 178px;
  height: 231px;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  list-style-type: none;
  background-color: #9b2423;
  list-style-position: inside;
}
.user-management-profile {
  cursor: pointer;
  height: 64px;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-dropdown-toggle08 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-dropdown-toggle08:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.user-management-text08 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-manage-helicopter {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-dropdown-toggle09 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-dropdown-toggle09:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.user-management-navlink2 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.user-management-user-management {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-dropdown-toggle10 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-dropdown-toggle10:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.user-management-navlink3 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  text-decoration: none;
}
.user-management-help-center-manage {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-color: #ffffff;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.user-management-dropdown-toggle11 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.user-management-dropdown-toggle11:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.user-management-text09 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-logout {
  cursor: pointer;
  display: inline-block;
  position: relative;
}
.user-management-dropdown-toggle12 {
  fill: #595959;
  color: #595959;
  width: 100%;
  height: 45.2px;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.user-management-dropdown-toggle12:hover {
  fill: #fff;
  color: #fff;
  background-color: #c72f2e;
}
.user-management-text10 {
  color: rgb(255, 255, 255);
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-list-container {
  width: 100%;
  height: 729px;
  display: flex;
  padding: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.user-management-list {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1400px;
  min-width: 320px;
  align-items: flex-start;
  border-color: #9B2423;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
  flex-direction: column;
}
.user-management-head-element {
  flex: 0 0 auto;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  border-color: #9B2423;
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.user-management-name-text {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-text11 {
  color: rgb(155, 36, 35);
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-email-text {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-text12 {
  color: rgb(155, 36, 35);
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-status-text {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-text13 {
  color: rgb(155, 36, 35);
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-edit-icon {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-icon2 {
  fill: #9B2423;
  width: 25px;
  height: 25px;
}
.user-management-block-text {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-text14 {
  color: rgb(155, 36, 35);
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.user-management-head-element1 {
  flex: 0 0 auto;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  border-color: #9B2423;
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.user-management-name-text1 {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-text15 {
  color: #000000;
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.user-management-email-text1 {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-text16 {
  color: #000000;
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.user-management-status-text1 {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-text17 {
  color: #000000;
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
.user-management-edit-icon1 {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-icon4 {
  fill: #9B2423;
  width: 25px;
  height: 25px;
}
.user-management-block-text1 {
  width: 20%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.user-management-text18 {
  color: #000000;
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
}
@media(max-width: 991px) {
  .user-management-logo1 {
    display: none;
  }
  .user-management-map {
    display: none;
  }
  .user-management-notifications {
    display: none;
  }
  .user-management-help-center {
    display: none;
  }
  .user-management-mobile-menu {
    display: inline-block;
  }
  .user-management-icon {
    fill: #ffffff;
    width: 28px;
    height: 28px;
  }
  .user-management-profile-icon-dropdown {
    height: 100px;
  }
  .user-management-text11 {
    font-size: 19px;
  }
  .user-management-text12 {
    font-size: 19px;
  }
  .user-management-text13 {
    font-size: 19px;
  }
  .user-management-text14 {
    font-size: 19px;
  }
  .user-management-text15 {
    color: #000000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .user-management-text16 {
    color: #000000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .user-management-text17 {
    color: #000000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
  .user-management-text18 {
    color: #000000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
  }
}
@media(max-width: 767px) {
  .user-management-navbar {
    top: 0px;
    left: 0px;
    position: absolute;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .user-management-logo1 {
    display: none;
  }
  .user-management-map {
    display: none;
  }
  .user-management-notifications {
    display: none;
  }
  .user-management-help-center {
    display: none;
  }
  .user-management-mobile-menu {
    display: inline-block;
  }
  .user-management-dropdown-toggle03 {
    align-items: center;
    justify-content: center;
  }
  .user-management-icon {
    fill: #ffffff;
    width: 32px;
    height: 32px;
  }
  .user-management-profile-icon-dropdown {
    top: 48px;
    left: -125px;
    height: 101px;
  }
  .user-management-text03 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .user-management-text06 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .user-management-text07 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .user-management-text11 {
    font-size: 17px;
  }
  .user-management-text12 {
    font-size: 17px;
  }
  .user-management-text13 {
    font-size: 17px;
  }
  .user-management-text14 {
    font-size: 17px;
  }
  .user-management-text15 {
    font-size: 16px;
  }
  .user-management-text16 {
    font-size: 16px;
  }
  .user-management-text17 {
    font-size: 16px;
  }
  .user-management-text18 {
    font-size: 16px;
  }
}
@media(max-width: 479px) {
  .user-management-navbar {
    padding: var(--dl-space-space-unit);
  }
}
@media(max-width: 464px) {
  .user-management-navbar {
    padding-left: 29px;
    padding-right: 16px;
  }
  .user-management-logo {
    width: 170px;
    height: 32px;
  }
  .user-management-links-box {
    gap: 2px;
    width: 172px;
  }
  .user-management-profile-icon-dropdown1 {
    top: 51px;
    left: -100px;
    height: 165px;
  }
  .user-management-list-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .user-management-list {
    min-width: auto;
  }
}
